import React from 'react'
import styles from "./footer.css";
import { Container } from 'react-bootstrap';

export const Footer = () => {
    return (
        <div className={styles}>
            <Container fluid className="footer">
            <div class="center">
            Copyright © 2020 by ECMP B.V. <br />
            All rights reserved.
            </div>
            </Container>
        </div>
    )
}


