import React from "react";
import styles from "./banner.css";
import hero from "../../img/screenshot.png";
import { Container } from "react-bootstrap";

function banner(props) {
  return (
    <div className={styles}>
      <Container fluid className="p-0" style={{ position: "relative" }}>
        <img src={hero} alt="Hero" class="header-image" />
        <div className="hero-text-box heroResponsive">{props.children}</div>
      </Container>
    </div>
  );
}

export default banner;
