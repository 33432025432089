import React from "react";
import Section from "../components/section/section";
import TranslateText from "../components/translatetext/translatetext";
import { Row, Col } from "react-bootstrap";
import Videocard from "../components/videocard/videocard";
import Scrolltop from "../components/scrolltop/scrolltop";

const Videopage = () => {
  return (
    <div>
      <Scrolltop />
      <Section label="hello.label" color="dark" id="Videos">
        <TranslateText label="section.videos.title.label" type="h2" />
        <TranslateText label="section.videos.text.label" type="p" />
      </Section>

      <Row className="g-0">
          <Col>
            <Videocard cardId="videosection.1" youtubeId="UpSeJ-8KW8s" />
          </Col>
          <Col>
            <Videocard cardId="videosection.2" youtubeId="NW5vOjwoFx0" />
          </Col>
        </Row>
        <Row className="g-0">
          <Col>
            <Videocard cardId="videosection.3" youtubeId="T9uk9-NrBhg" />
          </Col>
          <Col>
            <Videocard cardId="videosection.4" youtubeId="BYXVGAOgJvc" />
          </Col>
        </Row>
        <Row className="g-0">
          <Col>
            <Videocard cardId="videosection.5" youtubeId="NW5vOjwoFx0" />
          </Col>
          <Col xs={6}></Col>
        </Row>
    </div>
  );
};

export default Videopage;
