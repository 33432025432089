import React from "react";
import { useTranslation } from "react-i18next";
import NavDropdown from "react-bootstrap/NavDropdown";
import getUnicodeFlagIcon from "country-flag-icons/unicode";


const customClass = "nav-text";

function NavDropdownExample() {
  const { i18n } = useTranslation();
  // let lang = i18n.language
  const handleSelect = (eventKey) => {
    // alert(`selected ${eventKey}`)
    i18n.changeLanguage(eventKey);
  };

  var s = {
    color: "#0068B0",
    width: "2px",
    "text-decoration": "none",

    "text-transform": "uppercase",
    "font-size": "90%",
  };

  let vlag;
  switch (i18n.language) {
    case "en":
      vlag = getUnicodeFlagIcon("gb");
      break;
    case "nl":
      vlag = getUnicodeFlagIcon("nl");
      break;
    case "de":
      vlag = getUnicodeFlagIcon("de");
      break;
    default:
      vlag = getUnicodeFlagIcon("gb");
      break;
  }

  return (
    <NavDropdown
      bsPrefix={customClass}
      title={vlag}
      collapse="true"
      onSelect={handleSelect}
      className="d-flex justify-content-end align-items-bottom"
      id="menuRight"
    >
      <NavDropdown.Item style={s} eventKey="en" href="/">
        
          {getUnicodeFlagIcon("gb")}
        
      </NavDropdown.Item>

      <NavDropdown.Item style={s} eventKey="nl" collapse>
        {getUnicodeFlagIcon("nl")}
        {/* <Translatetext label="navbar.dutch.title.label" /> */}
      </NavDropdown.Item>

      {/* <NavDropdown.Item style={s} eventKey="de" href="/"> */}
        {/* {getUnicodeFlagIcon("de")} */}
        {/* <Translatetext label="navbar.german.title.label" /> */}
      {/* </NavDropdown.Item> */}
    </NavDropdown>
  );
}

export default NavDropdownExample;
