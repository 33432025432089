import React from 'react'
import Styles from './logo.css'

export default function logo() {
    return (
        // <img src={Logo} alt="Logo" className="logo logoResponsive"/>
        <div className={Styles}>
                   
        <div className="logoSize1 logoResponsive">
            <p className="ecmp">ECMP</p>
            <p className="artners">artners</p>
            <div className="bolcol1">
                <div className="bolletjes"></div>
                <div className="bolletjes"></div>
            </div>
            <div className="bolcol2">
                <div className="bolBlue"></div>
                <div className="bolletjes"></div>
            </div>
        </div> 
        </div>
    )
}
