import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";

let type;
let text;

const sectiontextstyle = (props) => {
  type = props.type;
  text = props.text;

  return (
    <span>
      {(() => {
        switch (type) {
          case "h1":
            return <h1>{text}</h1>;
          case "h2":
            return <h2>{text}</h2>;
          case "qsubtitle":
            return (
              <h3>
                <FontAwesomeIcon
                  icon={faQuoteLeft}
                  style={{ color: "rgb(0, 103, 176)" }}
                />
                <span> </span>
                {text}
                <span> </span>
                <FontAwesomeIcon
                  icon={faQuoteRight}
                  style={{ color: "rgb(0, 103, 176)" }}
                />
              </h3>
            );
          case "subtitle":
            return <h3>{text}</h3>;
          case "p":
            return <p>{text}</p>;
          default:
            return <span>{text}</span>;
        }
      })()}
    </span>
  );
};

export default sectiontextstyle;
