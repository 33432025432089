import React from "react";
// import PropTypes from "prop-types";
import YoutubeEmbed from "../YoutubeEmbed/YoutubeEmbed";
import { Card } from "react-bootstrap";
import TranslateText from "../translatetext/translatetext";
import "./videocard.css";

const Videocard = ({ cardId, youtubeId }) => {
  return (
    <div>
      <Card
        style={{ "background-color": "#f4f4f4" }}
        className="m-2 p-2 scroll card"
      >
        <YoutubeEmbed embedId={youtubeId} />
        <Card.Body>
          <Card.Title>
            <TranslateText label={`${cardId}.title`} />
          </Card.Title>
          <Card.Title>
            <TranslateText label={`${cardId}.subtitle`} />
          </Card.Title>
          <Card.Text>
            <TranslateText label={`${cardId}.text`} />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

// Videocard.PropTypes = {
//   cardId: PropTypes.string.isRequired,
//   youtubeId: PropTypes.string.isRequired,
// };

export default Videocard;
