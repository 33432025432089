import { Modal, Button } from "react-bootstrap";
import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import TranslateText from "../../components/translatetext/translatetext";
import hero from "../../img/screenshot.png";

function Contact() {
  const [show, setShow] = useState(true);
  const History = useHistory();

  const handleClose = () => {
    setShow(false);
    History.push("/"); //stuurt site terug naar home na sluiten
  };
  // const handleShow = () => setShow(true);

  const s = {
    color: "#0068B0",
    
  };
  
  return (
    <React.Fragment>
      <Container fluid className="p-0" style={{ position: "relative" }}>
        <img src={hero} alt="Hero" class="header-image" />
      </Container>
      <Modal style={s} show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>
            <TranslateText label="modal.contact.title.label" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <div>
                  <FontAwesomeIcon icon={faPhone} size="1x" />
                  <span>&nbsp;:&emsp;+31 (0)614-743465</span>
                </div>
                <div>
                  <FontAwesomeIcon icon={faPhone} size="1x" />
                  <span>&nbsp;:&emsp;+31 (0)614-743753</span>
                </div>
                <FontAwesomeIcon icon={faEnvelope} size="1x" />
                <span>&nbsp;:&emsp;info@ecmpartners.nl</span>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={12}>
                <br />
                <h4>Postadres</h4>
                <div>ECMP B.V.</div>
                <div>Thornsestraat 14</div>
                <div>6575-JJ Persingen</div>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12">
                <br />
              </Col>
            </Row>
            <Row>
              <div>
                <TranslateText label="modal.contact.kvk.label" />
                <span>&nbsp;&nbsp;:&emsp;09155304</span>
              </div>
            </Row>
            <Row>
              <div>
                <TranslateText label="modal.contact.btw.label" />
                <span>&nbsp;:&emsp;NL8151.82.260.B01</span>
              </div>
            </Row>
            <Row>
              <div>
                <TranslateText label="modal.contact.bank.label" />
                <span>&nbsp;:&emsp;NL.93.ABNA.0411631578</span>
              </div>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ "background-color": "#0068B0", borderColor: "#0068B0" }}
            variant="secondary"
            onClick={handleClose}
          >
            <TranslateText label="modal.contact.btnclose.label" />
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Contact;
