import React from "react";
import ScrollToTop from "react-scroll-up";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";

const Scrolltop = () => {
  return (
    <ScrollToTop
      showUnder={160}
      // duration={10}
      easing="easeInCirc"
      style={{
        position: "fixed",
        bottom: 50,
        right: 30,
        cursor: "pointer",
        transitionDuration: "0.1s",
        transitionTimingFunction: "linear",
        transitionDelay: "0s",
        "z-index": "99",
        color: "#ff9000",
      }}
    >
      <span>
        <FontAwesomeIcon
          icon={faArrowAltCircleUp}
          size="3x"
          style={{ filter: "drop-shadow(0px 0px 8px rgba(0,0,0,.5))" }}
        />
      </span>
    </ScrollToTop>
  );
};

export default Scrolltop;
