import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Textstyle from "../sectiontextstyle/sectiontextstyle";


const TranslateText = (props) => {
  const { t } = useTranslation();
  let text;

  //get translation from language file

  text=t(props.label);//om de linter tevreden te stellen

  text = <Trans i18nKey={props.label} />;

  return <Textstyle type={props.type} text={text}></Textstyle>;
  // return text
};

export default TranslateText;
