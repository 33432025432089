import React, { Suspense } from "react";
import { NavLink } from "react-router-dom";
import "../configuration/i18n";
import Section from "../components/section/section";
import Translatetext from "../components/translatetext/translatetext";
import Banner from "../components/banner/banner";
import YoutubeEmbed from "../components/YoutubeEmbed/YoutubeEmbed";
import { Button } from "react-bootstrap";
import Scrolltop from "../components/scrolltop/scrolltop";
import { openHelp } from "../configuration/globals";

// import ScrollUpButton from "react-scroll-up-button";

function Introductionpage() {
  return (
    <div>
      <Suspense fallback={null}>
        <Scrolltop />
        <Banner id="Banner">
          <Translatetext label="section.banner.title.label" />
        </Banner>
        <Section color="light" id="Introduction">
          <Translatetext label="section.solutions.title.label" type="h2" />
          <Translatetext label="section.solutions.text.label" type="p" />
        </Section>
        <Section color="dark" id="promotion">
          <Translatetext label="section.promotion.title.label" type="h2" />

          <YoutubeEmbed embedId="QPXeGE7kuWc" />
        </Section>

        <Section color="light" id="explorerintegration">
          <Translatetext
            label="section.explorerintegration.title.label"
            type="h2"
          />

          <Translatetext
            label="section.explorerintegration.subtitle.label"
            type="qsubtitle"
          />

          <Translatetext
            label="section.explorerintegration.text.label"
            type="p"
          />
        </Section>
        <Section color="dark" id="officeintegration">
          <Translatetext
            label="section.officeintegration.title.label"
            type="h2"
          />
          <Translatetext
            label="section.officeintegration.subtitle.label"
            type="qsubtitle"
          />
          <Translatetext
            label="section.officeintegration.text.label"
            type="p"
          />
        </Section>
        <Section color="light" id="release">
          <Translatetext label="section.release.title.label" type="h2" />
          <Translatetext
            label="section.release.subtitle.label"
            type="qsubtitle"
          />
          <Translatetext label="section.release.text.label" type="p" />
        </Section>
        <Section color="dark" id="documentediting">
          <Translatetext
            label="section.documentediting.title.label"
            type="h2"
          />
          <Translatetext
            label="section.documentediting.subtitle.label"
            type="qsubtitle"
          />
          <Translatetext label="section.documentediting.text.label" type="p" />
        </Section>

        {/* section voor verwijzing naar de video's */}

        <Section color="light" id="">
          <Translatetext label="section.to-the-videos.title.label" type="h2" />
          <Translatetext
            label="section.to-the-videos.subtitle.label"
            type="qsubtitle"
          />
          <Translatetext label="section.to-the-videos.text.label" type="p" />

          <Button
            style={{ "background-color": "#0068B0", borderColor: "#0068B0"}}
            size="sm"
          >
            <NavLink to="/videos" style={{ "text-decoration": "none", "color": "#fff" }}>
              <Translatetext label="section.to-the-videos.buttontext.label" />
            </NavLink>
          </Button>
        </Section>

        <Section color="dark" id="">
          <Translatetext label="section.help.title.label" type="h2" />
          <Translatetext label="section.help.subtitle.label" type="qsubtitle" />
          <Translatetext label="section.help.text.label" type="p" />
          <Button
            style={{ "background-color": "#0068B0", borderColor: "#0068B0" }}
            type="button"
            value="dit is de value"
            size="sm"
            onClick={openHelp}
          >
            <Translatetext label="section.help.buttontext.label" />
          </Button>
        </Section>
      </Suspense>
    </div>
  );
}
export default Introductionpage;
