import React from 'react'
import styles from './section.css';


const Section = (props) => {
  return (
   
    <div className={styles}>
      <section class={props.color==="dark" ? "section-callout-dark" : "section-callout-light"} id={(props.id) ?  props.id: ""}>
        {props.children}
    </section> 
    </div>
    
  )
}

export default Section

