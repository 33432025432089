import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "../../components/navbar/navbar";
import { Footer } from "../../components/footer/footer";
import Videopage from "../videopage";
import Introductionpage from "../introductionpage";
import Contact from "../contactmodal/mdlContact";

let styleobject = {
  // "max-width": "1024px"
};

export const Layout = (props) => {
  // let [contentpane, setContentpane] = useState(Introductionpage);

  return (
    <Suspense fallback={null}>
      <div style={styleobject} className="m-2">
        <Router>
          <Navbar />
          <Switch>
            <Route path="/introduction">
              <Introductionpage />
            </Route>
            <Route path="/videos">
              <Videopage />
            </Route>
            <Route path="/help">
              <h1>help</h1>
            </Route>
            <Route path="/contact">
              <Contact></Contact>
            </Route>
            <Route path="/">
              <Introductionpage />
            </Route>
          </Switch>

          <Footer />
        </Router>
      </div>
    </Suspense>
  );
};
