import React from "react";
import { NavLink as NavNew } from "react-router-dom";
import styles from "./navbar.css";
import Logo from "../logo/logo";
import Lang from "../langSelector/languageselector";
import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Col,
  NavItem,
} from "react-bootstrap";
import Translatetext from "../translatetext/translatetext";
import { openHelp } from "../../configuration/globals";

var s = {
  color: "#0068B0",
  "text-decoration": "none",
  padding: "0.0.0.0",
};

var icon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="20"
    fill="currentColor"
    class="bi bi-list"
    viewBox="0 0 16 16"
  >
    <path
      fill-rule="evenodd"
      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
    />
  </svg>
);

function navbar(props) {
  return (
    <div className={styles}>
      <Container
        fluid="true"
        className="d-flex containersize align-items-center"
      >
        <Navbar style={{ backgroundColor: "white" }} fixed="top">
          <Col
            xs={4}
            className="d-flex justify-content-start justify-content-xs-start"
          >
            <NavDropdown title={icon} id="menu" collapse="true">
              <NavItem
                id="menuItem"
                eventKey="1"
                onClick={props.introductionclicked}
              >
                <Nav.Link  eventKey="bla">
                  <NavNew style={s} to="/introduction">
                    <Translatetext label="navbar.introduction.title.label" />
                  </NavNew>
                </Nav.Link>
              </NavItem>
              <NavDropdown.Divider />
              <NavItem id="menuItem">
                <Nav.Link  eventKey="bla">
                  <NavNew style={s} to="/videos">
                    <Translatetext label="navbar.videos.title.label" />
                  </NavNew>
                </Nav.Link>
              </NavItem>
              <NavDropdown.Divider />
              <NavItem id="menuItem" onClick={openHelp}>
                <Nav.Link  eventKey="bla">
                  <NavNew style={s} to="/Introduction">
                    <Translatetext label="navbar.help.title.label" />
                  </NavNew>
                </Nav.Link>
              </NavItem>
              <NavDropdown.Divider />
              <NavItem id="menuItem">
                <Nav.Link  eventKey="bla">
                  <NavNew style={s} to="/contact">
                    <Translatetext label="navbar.contact.title.label" />
                  </NavNew>
                </Nav.Link>
              </NavItem>
            </NavDropdown>
          </Col>
          <Col xs={4} className="d-flex justify-content-center">
            <Logo className="flex-row-reverse .logoResponsive" />
          </Col>
          <Col
            xs={4}
            className="d-flex justify-content-end"
            style={{ "padding-right": "15px" }}
            alignContentCenter
          >
            <div
              style={{ "flex-direction": "column" }}
              className="d-flex align-items-center flexDirection={column}"
            >
              <Lang />
            </div>
          </Col>
        </Navbar>
      </Container>
    </div>
  );
}
export default navbar;
